import { useState , useEffect} from 'react'

function useDebounce(val, delay){

    const [dbVal, setDbVal]=useState(val);

    useEffect(()=>{
        const handler=setTimeout(()=>{
            setDbVal(val)
        },delay)

        return ()=>{
            clearTimeout(handler)
        }
    },[val,delay])

    return dbVal
}



export default useDebounce;