import React,{ useEffect, useState } from 'react';
import './App.css';
import axios from './api/axios'
import  { Toaster } from 'react-hot-toast';
import Background from './Components/layout/Background';
import { ThemeProvider } from './Components/layout/ThemeContext';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Login from './Components/pages/Login';
import Welcome from './Components/pages/Welcome'
import Register from './Components/pages/Register';
import ForgotPassword from './Components/pages/ForgotPassword';
import SideNav from './Components/layout/SideNav';

import { setCompany } from './features/companies';
import { setCategory } from './features/categories';
import { setTasks } from './features/Tasks';


import { CATEGORIES_URL, COMPANIES_URL, GETALLJOB_URL } from './api/ApiUrl';
import Profile from './Components/pages/Profile';
import Settings from './Components/pages/Settings';
import CreateCategory from './Components/Category/CreateCategory';
import CategoryTasks from './Components/pages/CategoryTasks';
import EditCategory from './Components/Category/EditCategory';
import CompanyTasks from './Components/pages/CompanyTasks';

import MyTasks from './Components/pages/MyTasks';
import DoneTasks from './Components/pages/DoneTasks';
import SideBar from './Components/layout/SideBar';

import Notifications from './Notifications/Notifications';
import PasswordReset from './Components/pages/PasswordReset';
import { setTaskPagination } from './features/taskPaginations';





function App() {
  const dispatch = useDispatch();
  const user=useSelector((state)=>state.user.value);
  const ProtectedRoute=({user, children})=>{
    if(user==null){
      return <Navigate to='/' replace/>
    }
    return children;
  }

  function Redirect({to}){
    let Navigate=useNavigate();
    useEffect(()=>{
      Navigate(to)
    });
    return null;
  }

  const getCompany=async()=>{
   
      const res=await axios.get(`${COMPANIES_URL}${user.id}`)
      dispatch(setCompany(res.data.companies))
    
  }
  const getCategories= async()=>{
   
      const res= await axios.get(`${CATEGORIES_URL}${user.id}`)
      dispatch(setCategory(res.data.categories));
    
  }

  // const getAllJobs=async()=>{
  //     const res=await axios.get(`${GETALLJOB_URL}${user.id}`)
  //     dispatch(setTasks(res.data.tasks))
  // }


  useEffect(()=>{
    if(user!=null){
    getCompany();
    getCategories();
    }
  },[user])

  
  return (
    <Background>
        {user!=null && <Notifications getCompany={getCompany} getCategories={getCategories} /> }

      <ThemeProvider>
        {user!=null?
        <SideNav/>
        :
        null}

      <Routes>
            <Route exact path='/doneTasks' element={<ProtectedRoute user={user}><DoneTasks/> </ProtectedRoute>}/>
            <Route exact path='/company/:compId' element={<ProtectedRoute user={user}><CompanyTasks/> </ProtectedRoute>}/>
            <Route exact path='/category/:compId/:catId/:activeJob' element={<ProtectedRoute user={user}><CategoryTasks/></ProtectedRoute>}/>
            <Route exact path='/category/:compId/:catId' element={<ProtectedRoute user={user}><CategoryTasks/></ProtectedRoute>}/>
            <Route exact path='/category/edit/:catId' element={<ProtectedRoute user={user}> <EditCategory/> </ProtectedRoute>}/>
            <Route exact path='/create/category' element={<ProtectedRoute user={user}><CreateCategory/></ProtectedRoute>}/>
            <Route exact path='/myTasks' element={<ProtectedRoute user={user}> <MyTasks/></ProtectedRoute>}/>
            <Route exact path='/settings' element={<ProtectedRoute user={user}><Settings/></ProtectedRoute>} /> 
            <Route exact path='/profile' element={<ProtectedRoute user={user}> <Profile/></ProtectedRoute>}/>
            <Route exact path='/profile/:compId' element={<ProtectedRoute user={user}> <Profile/></ProtectedRoute>}/>
            <Route exact path='/signUp' element={user!=null? <Redirect to='/myTasks'/>:<Register/>}/>
            <Route exact path='/passwordReset/:token' element={user!=null? <Redirect to='/myTasks'/>:<PasswordReset/> } />
            <Route exact path='/forgot-password' element={user!=null? <Redirect to='/myTasks'/>:<ForgotPassword/> }/>
            <Route exact path="/login" element= {user!=null? <Redirect to='/myTasks'/>:<Login/>}/>
            <Route exact path="/" element= {user!=null? <Redirect to='/myTasks'/>:<Welcome/>}/>
      </Routes>

      {user!=null?
        <SideBar/>
        :
        null}
        
      </ThemeProvider>
      <Toaster toastOptions={{className: 'escapeToaster'}} /> 
    </Background>
  );
}

export default App;
