import React, {useState, useEffect} from 'react'
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { useDispatch, useSelector } from 'react-redux';
import { GETDATA_URL, GETUSER_JOB } from '../../api/ApiUrl';
import Tasklist from '../Tasks/Tasklist';
import { v4 as uuidv4 } from "uuid";
import { setTaskPagination } from '../../features/taskPaginations';
import { setTasks } from '../../features/Tasks';
import { setPinnedTasks } from '../../features/PinnedTasks';

const MyTasks = () => {
    
    
  const dispatch=useDispatch();

    const user=useSelector(state=>state.user.value);
    const categs=useSelector((state)=>state.category.value);
    // const tasks=useSelector((state)=>state.allTasks.value); 

    // const [myTaskIds, setMyTaskIds]=useState([]);

    const [jobStatuses,setJobStatuses]=useState([]);
    const [paymentOptions,setPaymentOptions]=useState([]);
    const [paymentMethods,setPaymentMethods]=useState([]);
    const [jobTypes,setJobTypes]=useState([]);
    const [shipments,setShipments]=useState([]);
    const [productions,setProductions]=useState([]);
    const [quotes, setQuotes]=useState([]);
    const [checklists, setChecklists]=useState([]);
    const [employeelist,setEmployeelist]=useState([]);

    const [isLoad, setLoad]=useState(true);


    const getTaskIds = async() => {
        const res=await axios.get(`${GETUSER_JOB}${user.id}`);
        dispatch(setTaskPagination(res.data.tasks.next_page_url))
          dispatch(setTasks(res.data.tasks.data))
          dispatch(setPinnedTasks(res.data.pinned))
    }

    const getDefaultData= async(ids)=>{
        try{
           await axios.post(GETDATA_URL, {ids:ids}).then((res)=>{
            setJobStatuses(res.data.statuses)
            setPaymentOptions(res.data.paymentOptions)
            setPaymentMethods(res.data.paymentMethods)
            setJobTypes(res.data.jobTypes)
            setShipments(res.data.shipments)
            setProductions(res.data.productions)
            setQuotes(res.data.quotes)
            setChecklists(res.data.checklists)
            setEmployeelist(res.data.employees)    
            setLoad(false)
           })
      
          }catch(e){
            if(!e?.response){
              toast.error("No Server Response!");
              console.log(e);
          }else{
            if(e.response.status===429){
              toast.error('too many requests! please try again later')
            }else{
              console.log(e);
              toast.error("Oops! An error occured!");
            }
          }
        }
    }


    useEffect(()=>{
        var ids=categs.map((c)=>c.id);
        getDefaultData(ids);
        getTaskIds();

    },[categs])


  return !isLoad?(
    <div className='main-bodyLayout'>
        

       
        <Tasklist
        isMyTasks={true}
        isCategory={false}
        jobStatuses={jobStatuses}
        paymentOptions={paymentOptions}
        paymentMethods={paymentMethods}
        jobTypes={jobTypes}
        shipments={shipments}
        productions={productions}
        quotes={quotes}
        checklists={checklists}
        employeelist={employeelist}
        fromDoneTask={false}
        title={'My Tasks'}
        />
       

    </div>
  )
  :
  null
}

export default MyTasks