import { configureStore } from "@reduxjs/toolkit";


import userReducer from '../features/user';
import companyReducer from '../features/companies';
import categoryReducer from '../features/categories';
import customerReducer from '../features/customers';
import tasksReducer from '../features/Tasks';
import PinnedTasksReducer from "../features/PinnedTasks";
import taskPaginations from "../features/taskPaginations";

const store=configureStore({
    reducer:{
        user:userReducer,
        company:companyReducer,
        category:categoryReducer,
        customers:customerReducer,
        allTasks:tasksReducer,
        pinnedTasks:PinnedTasksReducer,
        taskPagination:taskPaginations,
    },
    
})

export default store;