import React from 'react'

const TaskLoader = () => {
   
  return (
    <div className='mx-1 mt-4 mb-3'>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>

       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>

       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>

       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>

       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       <div className='w-full h-8 mb-1 rounded-md bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
       
    </div>
  )
}

export default TaskLoader