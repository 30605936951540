import React,{useState, useEffect} from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import { Check,Close,Minus, Eye, Delete } from '../layout/Icons';
import { ThemeContext } from '../layout/ThemeContext';
import moment from 'moment';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { UPDATETASK_API } from '../../api/ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskStatus } from '../../features/Tasks';
import { isIdle, notify, requireRefresh } from '../../config/firebaseConfig';
import { updatePinnedTaskStatus } from '../../features/PinnedTasks';

const EditTask = ({task, setTask, priorityOptions, index, chIndex,category, setEdit}) => {

const dispatch=useDispatch(); 
const user=useSelector((state)=>state.user.value)  
const categories=useSelector((state)=>state.category.value)
const [openRepeat, setOpenRepeat]=useState(false);
const [isSave, setIsSave]=useState(false);
const { theme } = React.useContext(ThemeContext);
const customStyles = {
  menu: (provided, state) => {
      let backgroundColor = "#f1f5f9";
      if (theme === "dark") {
      backgroundColor = "#334155";
      }
      
      return {
      ...provided,
      padding: 0,
      fontSize: 12,
      margin: 2,
      backgroundColor,
      minWidth:'max-content',
      maxWidth:'300px',
      zIndex:'99',
      };
  },
  option: (provided, state,) => ({
      ...provided,
      padding: 3,
      color: state.isSelected ? "orangered" : "#64748b",
      backgroundColor: state.isSelected ? "#cbd5e1" : '',
      backgroundColor: state.isFocused ? "#cbd5e1" : "",
  }),

  control: (provided) => ({
      ...provided,
      fontSize: 13,
      padding: 1,
      // height: 24,
      minHeight: 14,
      border: 1,
      borderRadius: "0.375rem",
      boxShadow: "none",
      backgroundColor: "transparent",
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
  }),
  placeholder: (provided) => ({
      ...provided,
      color: "rgb(148 163 184)",
  }),
  valueContainer: () => ({
      fontSize: 13,
      padding: 0,
      margin: 0,
      minHeight: 17,
      display: "flex",
      flexWrap: "no-wrap",
      alignItems: "center",
  }),

  IndicatorsContainer: () => ({
      padding: 0,
  }),
  clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
  }),
  indicatorSeparator: () => () => null,

  dropdownIndicator: () => ({
      paddingLeft: 2,
      opacity: 0.5,
  }),
  multiValue: (provided) => ({
      ...provided,
      margin:0,
      padding:0,
      height: 24,
      border:'1px solid',
      borderRadius:'8px',
      backgroundColor:`${theme === "dark" ? `#0f172a` : `#f8fafc`}`,
      borderColor: `${theme === "dark" ? `#334155` : `#9ca3af`}`,
      justifyContent: "space-between",
  }),
  MultiValueContainer: (provided) => ({
      ...provided,
  }),
  multiValueLabel: (provided) => ({
      ...provided,
      padding: 1,
      height: 20,
  }),
  multiValueRemove: (provided) => ({
      ...provided,
      padding: 0,
      height: 20,
      width:'auto',
      borderRadius:'100%',
  }),
  singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      
      let color = "rgb(71 85 105)";

      if (theme === "dark") {
      color = "rgb(148 163 184)";
      }

      return { ...provided, opacity, transition, color };
  },
};


    const updatePrice = () => {

        var set=task.taskSet;
        var price=0;
        var discount=0;
        var charge=0;
        var total=0;
        if(task.price>0){
          price=parseFloat(task.price);
        }else{
          price=0;
        }
        if(task.discount>0){
          discount=parseFloat(task.discount);
        }else{
          discount=0;
        }
        if(task.charge>0){
          charge=parseFloat(task.charge)
        }else{
          charge=0;
        }
        if(task.vat){
          total=(price*set) - discount+charge
          total=total + total*(20/100)
        }else{
          total=(price*set) - discount+charge
        }
        setTask(task=>({...task, total:total}));
      }
      useEffect(()=>{
        updatePrice();
      },[task.price, task.discount, task.charge, task.vat, task.taskSet])


      const updateTask = async ()=>{
        try{

        await axios.post(`${UPDATETASK_API}${task.id}`, {job:JSON.stringify(task), userId:user.id}).then((res)=>{
            if(res.data.task!=null){

              if(res.data.task.pinned){
                dispatch(updatePinnedTaskStatus({index:index,chIndex:chIndex, task:res.data.task}))
              }else{
                dispatch(updateTaskStatus({index:index,chIndex:chIndex, task:res.data.task}))
              }


                const toSend=res.data.assignees;
                const refresh=res.data.needsRefresh;
               
                toSend.filter(a=>a.userId!==user.id).map(s=>{
                  var title=`Task [#${s.jobId}] has updates`
                  var body=`Task [#${s.jobId}] has been updated by ${user.Fname} ${user.Lname}`
                  var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
                  var receiverId=s.userId
                  var cat=categories.filter(c=>c.id==s.catId)[0]
                  var iconLink=''
                  var iconType=-1
                  if(cat.icon!=null){
                    iconLink=`${cat.icon}`
                    iconType=0
                  }else{
                    iconLink=`${cat.name}`
                    iconType=1
                  }
                  notify(title, body, onClickLink, receiverId, iconLink, iconType)
                })
                refresh.filter(r=>r!=user.id).map(ref=>{
                  requireRefresh(ref, true, false, false)
                })
                isIdle(user.id, true)
  
                toast.success('Task Updated!')
                setEdit(false)
            }else{
                toast.error('Task update Failed!')
            }
        })

        }catch(e){
            if(!e?.response){
              toast.error("No Server Response!");
              console.log(e);
            }else{
              console.log(e);
              toast.error("Oops! An error occured!");
            }
          }
      }

  return (
    <div className='editChildJob'>
        <div className='editChildArrow'></div>
        

      
        <div className='flex items-center justify-start gap-0.5 flex-wrap'>

            <Select
                menuPlacement="auto"
                defaultValue={priorityOptions.filter((data)=>(data.value==task.priority))}      
                onChange={(option) => {
                setTask(task=>({...task, priority:option.value}))
                setIsSave(true)
                }}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                options={priorityOptions}
                placeholder="Priority"
                formatOptionLabel={ option=>(
                  <div className='flex items-center justify-start gap-1'>
                    <div className={`w-2 h-2 rounded-full`} style={{backgroundColor:option.color}}></div>
                    <p className='font-semibold'>{option.label}</p>
                  </div>
                )
                }
            />
            
            <div className="flex flex-wrap items-center justify-start col-span-1">
            <p className='input-field-prepend'>qty</p>
            <input type='number' 
            value={task.qty} 
            onChange={(e)=>{setTask(task=>({...task, qty:e.target.value})); setIsSave(true)}} 
            min="1" step="1" 
            className='w-12 input-field-group' 
            placeholder='Qty' 
            />
            </div>

        <input
            type="text"
            defaultValue={task.jobDesc}
            onChange={(e) => {
                setTask(task=>({...task, jobDesc:e.target.value}));
                setIsSave(true)
            }}
            className="w-2/6 input-field"
            placeholder="Job Description"
        />

        <div className="flex flex-wrap items-center justify-start col-span-1">
            <p className='input-field-prepend'>set</p>
            <input type='number' 
            value={task.taskSet} 
            onChange={(e)=>{setTask(task=>({...task, taskSet:e.target.value})); setIsSave(true)}} 
            min="1" step="1" 
            className='w-10 input-field-group' 
            placeholder='Set' 
            />  
        </div>

        {category.isPrice?
        <>
        <div className="flex items-center justify-start w-24">
        <p className="input-field-prepend">
          <strong className="px-1">£</strong>
        </p>
        <input
          defaultValue={task.price}
          onChange={(e) => {
            setTask(task=>({...task, price:e.target.value}))
            setIsSave(true)
          }}
          type="number"
          min="1"
          step="0.01"
          className="w-full input-field-group"
          placeholder="Price"
        />
      </div>

      <div className="flex items-center justify-start w-20">
            <p className="input-field-prepend">
              <strong className="px-1">+</strong>
            </p>
            <input
              type="number"
              defaultValue={task.charge}
              onChange={(e) => {
                setTask(task=>({...task, charge:e.target.value}))
                setIsSave(true)
              }}
              min="0"
              step="0.01"
              className="w-full input-field-group"
              placeholder="Charge"
            />
          </div>
          
          <div className="flex items-center justify-start w-20">
            <p className="input-field-prepend">
              <strong className="px-1">-</strong>
            </p>
            <input
             defaultValue={task.discount}
              onChange={(e) => {
                setTask(task=>({...task, discount:e.target.value}))
                setIsSave(true)
              }}
              type="number"
              min="0"
              step="0.01"
              className="w-full input-field-group"
              placeholder="Discount"
            />
          </div>

          {task.price>0?
          <div className="flex justify-start gap-1 align-middle m-0.5 p-1">
            <input
              type="checkbox"
              value="1"
              className="checkbox"
              onChange={(e) => {setTask(task=>({...task, vat:!task.vat})); setIsSave(true) }}
              id={`vat.${task.id}`}
              checked={task.vat}
              />
            <label htmlFor={`vat.${task.id}`} className="checkbox-label">
              Vat
            </label>
          </div>
          :
          null
          }
        
        {task.paid>=0?
        <div className="flex items-center justify-start w-24">
            <p className="input-field-prepend">rcvd</p>
            <input
              type="number"
              defaultValue={task.paid}
              onChange={(e) => {
                setTask(task=>({...task, paid:e.target.value}))
                setIsSave(true)
              }}
              min="0"
              step="0.01"
              className="w-full input-field-group"
              placeholder="paid"
              />
        </div>
          :
          null
            }
        </>
        :
        null
        }
        
        <DatePicker
        selected={new Date(task.turnaround)}
        
        dateFormat="dd,MMM-yy"
        className="w-24 input-field"
        onChange={(date) => {
          setTask(task=>({...task, turnaround:moment(date).format('Y-MM-DD')}))
          setIsSave(true)}}
        placeholderText="Turnaround"
        />
        


        {category.isRepeat ? 
            <div className="flex justify-start gap-1 items-center m-0.5">
            {task.repeat ? (
                <>
            <button
                className="icon-btn"
                onClick={() => {
                setOpenRepeat(!openRepeat);
                }}
                type={'button'}
            >
                <Eye iconClass={'w-4 h-4'}/>
            </button>
                <p className="checkbox-label">repeat</p>
                </>
            ) : 
                <>
                    <input
            type="checkbox"
            value="1"
            className="checkbox"
            onChange={() => {
                if (task.repeat) {
                setTask(task=>({...task, repeat:false, repeatDate:null, repeatType:1}))
                setOpenRepeat(false)
                setIsSave(true)
                } else {
                setTask(task=>({...task, repeat:true}))
                setOpenRepeat(true)
                setIsSave(true)
                }
            }}
            checked={task.repeat}
            id={`renew.${task.id}`}
            />
            
            <label htmlFor={`renew.${task.id}`} className="checkbox-label">
            repeat
            </label>
            </>
            }
            

            <div
            className={`popUpBox ${
                openRepeat
                ? `scale-100 opacity-100`
                : `scale-0 opacity-0`
            }`}
            >
            <div className="flex items-center justify-between">
                <p className="font-semibold text-center">Set repeater</p>
                <div className="flex items-center justify-end gap-1">
                <button
                    onClick={() => {
                    if (task.repeatDate !== '1970-01-01') {
                        setOpenRepeat(!openRepeat)
                    }
                    }}
                    className={`accept-btn ${
                    task.repeatDate !== '1970-01-01'
                        ? `cursor-pointer`
                        : `cursor-not-allowed`
                    }`}
                    type={'button'}
                >
                    <Check iconClass={'w-4 h-4'}/>
                </button>
                <button
                    onClick={() => {
                    setOpenRepeat(!openRepeat)
                    }}
                    className="info-btn"
                    type={'button'}
                >
                    <Minus iconClass={'w-4 h-4'} />
                </button>
                <button
                    onClick={() => {
                    setTask(task=>({...task, repeat:false, repeatDate:null, repeatType:1}))
                    setOpenRepeat(false)
                    }}
                    className="reject-btn"
                    type={'button'}
                >
                    <Close iconClass={'w-4 h-4'} />
                </button>
                </div>
            </div>

                <div className="flex items-center justify-between gap-3 mt-1">
                    <DatePicker
                    selected={task.repeatDate? new Date(task.repeatDate) : new Date()}
                    dateFormat="dd,MMM-yy"
                    className="min-w-full input-field"
                    onChange={(date) => {
                        setTask(task=>({...task, repeatDate:date}))
                    }}
                    placeholderText="repeater date"
                    />
                    <div className="flex items-center justify-end gap-1">
                    <button
                        onClick={() => {
                        setTask(task=>({...task, repeatType:1}))
                        }}
                        className={`repeater-btn ${
                        task.repeatType === 1
                            ? `bg-orange-500 border-orange-600 text-slate-50`
                            : `bg-transparent text-slate-700 dark:text-slate-400`
                        }`}
                        type={'button'}
                    >
                        Once
                    </button>
                    <button
                        onClick={() => {
                        setTask(task=>({...task, repeatType:2}))
                        }}
                        className={`repeater-btn ${
                        task.repeatType === 2
                            ? `bg-orange-500 border-orange-600 text-slate-50`
                            : `bg-transparent text-slate-700 dark:text-slate-400`
                        }`}
                    >
                        Monthly
                    </button>
                    <button
                        onClick={() => {
                        setTask(task=>({...task, repeatType:3}))
                        }}
                        className={`repeater-btn ${
                        task.repeatType === 3
                            ? `bg-orange-500 border-orange-600 text-slate-50`
                            : `bg-transparent text-slate-700 dark:text-slate-400`
                        }`}
                        type={'button'}
                    >
                        Yearly
                    </button>
                    </div>
                </div>
                </div>
            </div>
            :
            null
        }

        <div className='flex items-center justify-end gap-0.5 ml-auto'>

        <Transition
        show={isSave}
        enter="transition-all translate duration-150"
        enterFrom="opacity-0 translate-x-6"
        enterTo="opacity-100 translate-x-0"
        leave="transition-all translate duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-6"
        >
        <button className='accept-btn' onClick={()=>updateTask()}><Check iconClass={'w-4 h-4'}/></button>
        </Transition>
        </div>

        </div>

       
            

       

    

       
     
    </div>
  )
}

export default EditTask