import { getDoc } from 'firebase/firestore'
import React,{useEffect, useState} from 'react'
import { avatarStorage_LINK } from '../../api/ApiUrl'
import Avatar from '../layout/Avatar'
import { Food, InCall, LongBreak, Salah, ShortBreak, Washroom } from '../layout/Icons'

const UserBubbles = ({user}) => {

    const setRingColor=(id)=>{
        if(id==0){
            return 'ring-green-400'
        }else if(id==1){
            return 'ring-indigo-800'
        }else if(id==2){
            return 'ring-orange-500'
        }else if(id==3){
            return 'ring-red-500'
        }else if(id==4){
            return 'ring-cyan-500'
        }else if(id==5){
            return 'ring-purple-500'
        }else if(id==6){
            return 'ring-lime-500'
        }
        else if(id==-1){
            return 'ring-slate-200 dark:ring-slate-700 opacity-50'
        }
    }
    const [userData, setuserData]=useState(null);
    useEffect(()=>{
        const getUserData=async()=>{
            let userData=await getDoc(user.userLink);
            if(userData.exists()){
               const result=userData.data()
                setuserData(result)
                
            }
        }

        getUserData()

        return(()=>{
            setuserData(null);
        })
    },[])

  return userData!=null?(
    <div className={`${setRingColor(user.activity)} ${user.activity==-1? 'hidden': ''} group relative z-50 rounded-full min-w-max min-h-max ring-2 ring-offset-1 ring-offset-slate-50 dark:ring-offset-slate-700`}>
        {userData.photoUrl!=null?
        <img src={`${avatarStorage_LINK}${userData.photoUrl}`} className='w-6 h-6 rounded-full'/>
        :
        <Avatar value={userData.userName} width='6' height='6' color={'bg-blue-200 dark:bg-blue-800/70'} />
        }

        <Activity id={user.activity}/>
        <p className='px-1 py-0.5 text-xs font-semibold scale-0 group-hover:scale-100 absolute transition-all duration-150 ease-in-out -top-5 right-0 origin-bottom-right bg-slate-700 text-white whitespace-nowrap rounded-md'>{userData.userName}</p>
    </div>
  ):
  null
}

const Activity=({id})=>{
    return (
        <div className='absolute -right-1 -top-1'>
        {id==1 && 
        <div className='w-4 h-4 p-0.5 text-white bg-indigo-800 rounded-full'>
            <Washroom iconClass={'w-3 h-3'}/>
        </div>
        }

        {id==2 && 
         <div className='w-4 h-4 p-0.5 text-white bg-orange-500 rounded-full'>
         <ShortBreak iconClass={'w-3 h-3'}/>
        </div>
        }

        {id==3 && 
        <div className='w-4 h-4 p-0.5 text-white bg-red-500 rounded-full'>
        <LongBreak iconClass={'w-3 h-3'}/>
        </div>
        }

        {id==4 && 
         <div className='w-4 h-4 p-0.5 text-white bg-cyan-500 rounded-full'>
        <Salah iconClass={'w-3 h-3'}/>
        </div>
        }

        {id==5 && 
         <div className='w-4 h-4 p-0.5 text-white bg-purple-500 rounded-full'>
        <Food iconClass={'w-3 h-3'}/>
        </div>
        }

        {id==6 && 
         <div className='w-4 h-4 p-0.5 text-white bg-lime-500 rounded-full'>
        <InCall iconClass={'w-3 h-3'}/>
        </div>
        }


        </div>
    )
}

export default UserBubbles