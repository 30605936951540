import React, {useState, useEffect, Suspense} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react'
import { CheckCircle, ChevronUp, Delete, Duplicate, Forward, Link, Note, Pencil, Pin, SideBarIcon } from '../layout/Icons';
import Avatar from '../layout/Avatar'
import { CategorylogoStorage_LINK, DELETEJOB, DONETASK_URL, PIN_TASK, REVERTTASK_URL, UPDATEPAYMENTMETHOD_URL, UPDATEPAYMENTOPTION_URL, UPDATESECONDARYOPTION_URL, UPDATESTATUS_URL } from '../../api/ApiUrl';

import Moment from 'react-moment';
import axios from "../../api/axios";
import toast from 'react-hot-toast';

//import OptionDropdown from '../layout/OptionDropdown';
//import SecondaryOptionDropdown from '../layout/SecondaryOptionDropdown';

import { deleteTask, pushToPinTask, updateTaskStatus } from '../../features/Tasks';
import EditTask from './EditTask';
import ForwardTask from './ForwardTask';
import DoneTaskButton from '../layout/DoneTaskButton';
import RevertTaskButton from '../layout/RevertTaskButton';
import { isIdle, notify, requireRefresh } from '../../config/firebaseConfig';
import { addToPinTask, removeFromPinTask, updatePinnedTaskStatus } from '../../features/PinnedTasks';

const  OptionDropdown =React.lazy(()=>import('../layout/OptionDropdown') )
const SecondaryOptionDropdown=React.lazy(()=>import('../layout/SecondaryOptionDropdown'))

const Task = ({
    job,
    index,
    chIndex,
    referenceId,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    priorityOptions,
    jobTypes,
    shipments,
    productions,
    quotes,
    checklists,
    employeelist,
    sideTaskId,
    setSideTaskId,
    setSideTask,
    fromDoneTask,
    activeTask,
    dropRev,
    watching,
    fromSearch

}) => {
    const [task, setTask]=useState([]);

    const dispatch=useDispatch();
    const user=useSelector((state)=>state.user.value);
    const categories=useSelector((state)=>state.category.value);
    const [category,setCategory]=useState(null)
    
    const [edit,setEdit]=useState(false);
    const [forward, setForward]=useState(false);
    const [isDelete, setIsDelete]=useState(false);


    const [priorityDateColor,setPriorityDateColor]=useState(null);

    useEffect(()=>{
      setTask(job);
      setCategory(categories.filter(op=>op.id==job.categoryId)[0])
      setPriorityDateColor(priorityOptions.filter(op=>op.value==job.priority)[0])
      return(()=>{
        setTask([])
        setCategory(null)
        setEdit(false)
        setForward(false)
        setIsDelete(false)
        setPriorityDateColor(null)

      })

    },[job])
    
    const copyCode = ()=>{
      var copy=`${job.jobDesc}`;
      navigator.clipboard.writeText(copy);
      toast.success('Copied to Clipboard!')
    }

    const toggleSideBar = ()=>{
      if(sideTaskId!==task.id){
        setSideTaskId(task.id);
        setSideTask(task);
      }else{
        setSideTaskId(-1);
        setSideTask([]);
      }
     
    }

    const updateStatus = async(id)=>{
      try{
       
        var data=JSON.stringify({
          status:id,
          userId:user.id,
        })
        await axios.post(`${UPDATESTATUS_URL}${task.id}`, data).then((res)=>{
          if(res.data.task!=null){

            if(res.data.task.pinned){
              dispatch(updatePinnedTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
            }else{
              dispatch(updateTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
            }

          const toSend=res.data.assignees;
          const refresh=res.data.needsRefresh;
           
            toSend.filter(a=>a.userId!==user.id).map(s=>{
              var title=res.data.title
              var body=res.data.body
              var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
              var receiverId=s.userId
              var cat=categories.filter(c=>c.id==s.catId)[0]
              var iconLink=''
              var iconType=-1
              if(cat.icon!=null){
                iconLink=`${cat.icon}`
                iconType=0
              }else{
                iconLink=`${cat.name}`
                iconType=1
              }
              notify(title, body, onClickLink, receiverId, iconLink, iconType)
            })
            refresh.filter(r=>r!=user.id).map(ref=>{
              requireRefresh(ref, true, false, false)
            })      
            
          }
      
        toast.success('Job Status Updated')
        })

      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }else{
          if(e.response.status==429){
            toast.error('too many requests! please try again later')
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
    }

    const updatePaymentOption=async(id, rcvd)=>{
      try{
        var data=JSON.stringify({
          paymentOption:id,
          rcvd:rcvd,
          userId:user.id,
        })
       
        await axios.post(`${UPDATEPAYMENTOPTION_URL}${task.id}`, data).then((res)=>{
          if(res.data.task!=null){
          
          if(res.data.task.pinned){
              dispatch(updatePinnedTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
            }else{
              dispatch(updateTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
            }

          const toSend=res.data.assignees;
          const refresh=res.data.needsRefresh;
           
            toSend.filter(a=>a.userId!==user.id).map(s=>{
              var title=res.data.title
              var body=res.data.body
              var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
              var receiverId=s.userId
              var cat=categories.filter(c=>c.id==s.catId)[0]
              var iconLink=''
              var iconType=-1
              if(cat.icon!=null){
                iconLink=`${cat.icon}`
                iconType=0
              }else{
                iconLink=`${cat.name}`
                iconType=1
              }
              notify(title, body, onClickLink, receiverId, iconLink, iconType)
            })
            refresh.filter(r=>r!=user.id).map(ref=>{
              requireRefresh(ref, true, false, false)
            })      
            
          toast.success('Payment Option Updated!')
          }
        })
      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }else{
          if(e.response.status==429){
            toast.error('too many requests! please try again later')
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
    }


    const updatePaymentMethod = async(id)=>{
      try{
       
        var data=JSON.stringify({
          paymentMethod:id,
          userId:user.id,
        })
        await axios.post(`${UPDATEPAYMENTMETHOD_URL}${task.id}`, data).then((res)=>{

          if(res.data.task!=null){
           
          if(res.data.task.pinned){
            dispatch(updatePinnedTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
          }else{
            dispatch(updateTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
          }


            const toSend=res.data.assignees;
            const refresh=res.data.needsRefresh;
             
              toSend.filter(a=>a.userId!==user.id).map(s=>{
                var title=res.data.title
                var body=res.data.body
                var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
                var receiverId=s.userId
                var cat=categories.filter(c=>c.id==s.catId)[0]
                var iconLink=''
                var iconType=-1
                if(cat.icon!=null){
                  iconLink=`${cat.icon}`
                  iconType=0
                }else{
                  iconLink=`${cat.name}`
                  iconType=1
                }
                notify(title, body, onClickLink, receiverId, iconLink, iconType)
              })
              refresh.filter(r=>r!=user.id).map(ref=>{
                requireRefresh(ref, true, false, false)
              })      
              
            toast.success('Payment Method Updated!')
          }

        })

      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }else{
          if(e.response.status==429){
            toast.error('too many requests! please try again later')
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
    }

    const updateSecondaryOption=async(id, typeCode)=>{
      try{
        var data=JSON.stringify({
          option:id,
          type:typeCode,
          userId:user.id,
        })

        await axios.post(`${UPDATESECONDARYOPTION_URL}${task.id}`, data).then((res)=>{
          if(res.data.task!=null){
            
          if(res.data.task.pinned){
            dispatch(updatePinnedTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
          }else{
            dispatch(updateTaskStatus({index:index,chIndex:chIndex, task:res.data.task, fromSearch:fromSearch}))
          }

            const toSend=res.data.assignees;
            const refresh=res.data.needsRefresh;
             
              toSend.filter(a=>a.userId!==user.id).map(s=>{
                var title=res.data.title
                var body=res.data.body
                var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
                var receiverId=s.userId
                var cat=categories.filter(c=>c.id==s.catId)[0]
                var iconLink=''
                var iconType=-1
                if(cat.icon!=null){
                  iconLink=`${cat.icon}`
                  iconType=0
                }else{
                  iconLink=`${cat.name}`
                  iconType=1
                }
                notify(title, body, onClickLink, receiverId, iconLink, iconType)
              })
              refresh.filter(r=>r!=user.id).map(ref=>{
                requireRefresh(ref, true, false, false)
              })      
              
            toast.success('Task Updated!')
          }
        })

      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }else{
          if(e.response.status==429){
            toast.error('too many requests! please try again later')
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
    }


    const deleteJob= async()=>{
      try{
        await axios.delete(`${DELETEJOB}${task.id}`).then((res)=>{
          if(task.pinned){
            dispatch(removeFromPinTask({index:index, chIndex:chIndex}))
          }else{
            dispatch(deleteTask({index:index, chIndex:chIndex}))
          }
          const refresh=res.data.needsRefresh;
          refresh.filter(r=>r!=user.id).map(ref=>{
            requireRefresh(ref, true, false, false)
          })      
          setSideTaskId(-1);
          setSideTask([]);
          toast.error(res.data.message);
        })
      }catch(e){
        if(e.response && e.response.status==429){
          toast.error('too many requests! please try again later')
        }else{
          console.log(e);
          toast.error("Oops! An error occured!");
        }
      }
    }

    const doneTask = async()=>{
      try{
          await axios.post(DONETASK_URL, JSON.stringify({id:task.id, userId:user.id})).then((res)=>{
              toast.success('Task Moved to Done!');

              if(task.pinned){
                dispatch(removeFromPinTask({index:index, chIndex:chIndex}))
              }else{
                dispatch(deleteTask({index:index, chIndex:chIndex}))
              }
              
              const refresh=res.data.needsRefresh;


              refresh.filter(r=>r!=user.id).map(ref=>{
                requireRefresh(ref, true, false, false)
              })     
              setSideTaskId(-1);
              setSideTask([]);
          })
      }catch(e){
      if(e.response && e.response.status==429){
        toast.error('too many requests! please try again later')
      }else{
        console.log(e);
        toast.error("Oops! An error occured!");
      }
    }
  }

  const revertTask = async()=>{
    try{
        await axios.post(REVERTTASK_URL, JSON.stringify({id:task.id, userId:user.id})).then((res)=>{
            toast.success('Task Reverted!');
            dispatch(deleteTask({index:index, chIndex:chIndex}))
            const refresh=res.data.needsRefresh;
              refresh.filter(r=>r!=user.id).map(ref=>{
                requireRefresh(ref, true, false, false)
              })     
            setSideTaskId(-1);
            setSideTask([]);
        })
    }catch(e){
    if(e.response && e.response.status==429){
      toast.error('too many requests! please try again later')
    }else{
      console.log(e);
      toast.error("Oops! An error occured!");
    }
  }
}



const pinTask=async()=>{
  try{
    await axios.post(PIN_TASK, JSON.stringify({jobId:task.id, userId:user.id})).then((res)=>{
       
        if(res.data.task.pinned){
          dispatch(pushToPinTask({index, chIndex}))
          dispatch(addToPinTask({task:res.data.task, ref:referenceId}))
          toast.success('Task Pinned!',{icon:"📌"});
        }else{
          dispatch(removeFromPinTask({index, chIndex}))
          toast.success('Task Unpinned!',{icon:"📌"});
        }
      
        const refresh=res.data.needsRefresh;
          refresh.filter(r=>r!=user.id).map(ref=>{
            requireRefresh(ref, true, false, false)
          }) 

          const toSend=res.data.assignees;
          toSend.filter(a=>a.userId!==user.id).map(s=>{
            var title=res.data.title
            var body=res.data.body
            var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
            var receiverId=s.userId
            var cat=categories.filter(c=>c.id==s.catId)[0]
            var iconLink=''
            var iconType=-1
            if(cat.icon!=null){
              iconLink=`${cat.icon}`
              iconType=0
            }else{
              iconLink=`${cat.name}`
              iconType=1
            }
            notify(title, body, onClickLink, receiverId, iconLink, iconType)
          })

        setSideTaskId(-1);
        setSideTask([]);
    })
}catch(e){
if(e.response && e.response.status==429){
  toast.error('too many requests! please try again later')
}else{
  console.log(e);
  toast.error("Oops! An error occured!");
}
}
}


const editTask=()=>{
  setEdit(!edit);
  isIdle(user.id, edit);
}





  return (
    <div>
        <div className={`hover:bg-slate-300/50 rounded-md dark:hover:bg-slate-900 joblistChild border-b border-slate-300 dark:border-slate-900  ${watching ? 'bg-red-300 dark:bg-red-700/50': 'bg-white dark:bg-slate-800'}  ${edit? 'bg-slate-300/50 dark:bg-slate-900/50': '' } ${(sideTaskId==task.id || activeTask==task.id)? 'outline outline-orange-500 dark:outline-orange-600': 'outline-0'}`}>
            
            <div className='grid items-center justify-start grid-cols-1 gap-1 md:grid-cols-2 3xl:grid-cols-3'>

            <div className='flex items-center justify-start gap-1'>
              <p  className='px-0.5 text-center text-xs font-semibold text-slate-600 dark:text-slate-300 min-w-[1.5rem]'>{task.id}</p>
              {category!=null?
              <div key='categoryIcon' className='relative z-10 group min-w-max min-h-max'>
                <span className='popUp group-hover:scale-100'>{category.name}</span>
                {category.icon?
                <img src={`${CategorylogoStorage_LINK}${category.icon}`} className='w-6 h-6 p-0.5 rounded-full bg-slate-400 dark:bg-slate-900' alt='icon'/>
                :
                <Avatar value={category.name} width={'6'} height={'6'} />
                }
              </div>
              :
              null
              }
             
              {priorityOptions.filter(op => op.value==task.priority).map(op=>
              <div className='relative cursor-pointer group' key={op.value}>
              <p className={`mx-0.5 px-0.5 text-xs font-semibold text-slate-50 border rounded-md w-fit`} style={{color: op.color, borderColor:op.color}}>
              {op.label.slice(0,3)}
              </p>
              <span className='popUp group-hover:scale-100'>
                 <Moment date={task.created_at} format="DD,MMM'YY"/> - <Moment date={task.turnaround} format="DD,MMM'YY"/>
                </span>
              </div>
              )} 
              <div className='relative z-0 group'>
              <div className='w-[15rem] lg:w-[25rem]  3xl:w-[19rem] inline-flex items-center gap-1'>
                <p className='text-sm font-medium truncate text-slate-700 dark:text-slate-300'>
                  {task.qty}x {task.jobDesc} 
                </p>
                <p className='text-sm font-medium text-slate-700 dark:text-slate-300 whitespace-nowrap'>| {task.taskSet} set</p>
              <button className='hidden icon-btn group-hover:block' onClick={()=>{copyCode()}}>{<Duplicate iconClass={'w-4 h-4'}/>}</button>
              </div>
            
              {task.jobDesc && task.jobDesc.length>30?
              <>
              <div className='jobDescFloat'>
                <div className='flex items-center justify-start gap-1'>
                <p className='text-sm font-semibold text-slate-700 dark:text-slate-300 whitespace-nowrap'>
                {task.qty}x {task.jobDesc} | {task.taskSet} set
                </p> 
                </div>
                <div className='jobDescFloatArrow'></div>
              </div>
              </>
              :
              null}

              </div>
            </div>

            <Suspense fallback={<div className='text-xs font-semibold text-default'>Loading...</div>}>
            <div className={`flex items-center justify-start gap-1 md:justify-end 3xl:justify-start ${task.referenceId!=null && 'xl:-ml-0'}`}>
            {category!=null &&
              <OptionDropdown 
              key={`${task.id}Status-${task.jobStatus}`}
              items={jobStatuses} 
              selected={task.jobStatus} 
              filterCatId={task.categoryId}
              clickEventFunction={updateStatus}
              type={'jobStatus'}
              prompt={false}
              roleId={category.roleId}
              dropRev={dropRev}
              />
            }
              
              {category!=null && category.isPrice?
              <>
              <OptionDropdown
              key={`${task.id}pOption`}
              items={paymentOptions}
              selected={task.paymentOption}
              filterCatId={task.categoryId}
              clickEventFunction={updatePaymentOption}
              type={'paymentOption'}
              prompt={true}
              promptValue={task.paid}
              roleId={category.roleId}
              dropRev={dropRev}
              />
              <OptionDropdown
              key={`${task.id}pMethod`}
              items={paymentMethods}
              selected={task.paymentMethod}
              filterCatId={task.categoryId}
              clickEventFunction={updatePaymentMethod}
              type={'paymentMethod'}
              prompt={false}
              roleId={category.roleId}
              dropRev={dropRev}
              />
            
              <div className='flex items-center justify-center mb-1.5'>
              {task.paid>0 && task.total-task.paid!=0?
                <div className='p-0.5 bg-green-200 dark:bg-slate-900/40 text-green-600 rounded-l-md border border-r-0 border-green-600 text-xs font-semibold'>
                £{parseFloat(task.paid).toFixed(2)} 
                </div>
                :
                null
                }

                {task.total-task.paid!=0 && task.paid>=0?
                <div className='p-0.5 bg-red-200 dark:bg-slate-900/40 text-red-600 rounded-r-md border  border-red-600 text-xs font-semibold'>
                £{parseFloat(task.total-task.paid).toFixed(2)}
                </div>
                  :
                null}
                </div>

                <div className='relative z-10 group mb-1.5'>
                  {task.paid>=0?
                  <p className='text-sm font-semibold text-center text-slate-700 dark:text-slate-400'>£{parseFloat(task.total).toFixed(2)}</p>
                  :
                  <s className='text-sm font-semibold text-center text-slate-700 dark:text-slate-400' >£{parseFloat(task.total).toFixed(2)}</s> 
                  }
                  <p className='popUp group-hover:scale-100'>£{task.price} {task.discount>0? `- £${task.discount}` : ''} {task.charge>0? `+ £${task.charge}` : ''} = {parseFloat(task.total).toFixed(2)}</p>
                </div>
              </>
              :
              null}
            </div>
            </Suspense>

            <div className='flex items-center justify-between gap-1 md:col-span-2 2xl:col-span-2 3xl:col-span-1'>
            <Suspense fallback={<div className='text-xs font-semibold text-default'>Loading...</div>}>
              { category!=null? 
              <div className={`flex items-center justify-start gap-1 ${task.referenceId!=null && 'xl:-ml-0'}`}>
                
                {category.isQuote?
                <SecondaryOptionDropdown
                key={`${task.id}Quote`}
                items={quotes}
                selected={task.quote}
                filterCatId={task.categoryId}
                clickEventFunction={updateSecondaryOption}
                type={'Quote'}
                typeCode={3}
                roleId={category.roleId}
                dropRev={dropRev}
                />
                :
                null  
              }
              
              {category.isProduction?
                <SecondaryOptionDropdown
                items={productions}
                selected={task.production}
                filterCatId={task.categoryId}
                clickEventFunction={updateSecondaryOption}
                type={'Production'}
                typeCode={1}
                roleId={category.roleId}
                dropRev={dropRev}
                />
                :
                null  
              }

              {category.isShipment?
                <SecondaryOptionDropdown
                items={shipments}
                selected={task.shipment}
                filterCatId={task.categoryId}
                clickEventFunction={updateSecondaryOption}
                type={'Shipment'}
                typeCode={2}
                roleId={category.roleId}
                dropRev={dropRev}
                />
                :
                null  
              }

              

              {category.isJobType?
                <SecondaryOptionDropdown
                items={jobTypes}
                selected={task.jobType}
                filterCatId={task.categoryId}
                clickEventFunction={updateSecondaryOption}
                type={'Job Type'}
                typeCode={4}
                roleId={category.roleId}
                dropRev={dropRev}
                />
                :
                null  
              }

              </div>
              :
              null
            }
            </Suspense>
    
            <div className='flex items-center justify-end gap-1'>

            {task.notes!=null?
              <button className='display-btn'><Note iconClass={'w-3 h-3'}/></button>
             :
             null}

            {task.travel?
            <button className='display-btn'><Forward iconClass={'w-3 h-3'}/></button>
            :
            null}
             
            {category!=null && category.roleId>=1?
            <>
            {fromDoneTask?
            <RevertTaskButton revertTask={revertTask}/>
            :
            <DoneTaskButton 
            jobStatuses={jobStatuses}
            paymentOptions={paymentOptions}
            status={task.jobStatus}
            payment={task.paymentOption}
            isPrice={category.isPrice}
            doneTask={doneTask}
            />
            }
            
            <button className='icon-btn' onClick={()=>{pinTask()}}>{task.pinned? <Pin iconClass={'w-4 h-4 fill-red-500'}/> : <Pin iconClass={'w-4 h-4'}/>} </button>

            {edit?
            <button className='delete-btn' onClick={()=>{setIsDelete(true)}}><Delete iconClass={'w-4 h-4'}/></button>
                :
            null}
            <button className='icon-btn' onClick={()=>{editTask()}}> {edit? <ChevronUp iconClass={'w-4 h-4'}/> : <Pencil iconClass={'w-4 h-4'}/>} </button>
            </>
            :
            null
            }
            {!fromDoneTask && category!=null && category.roleId>=1?
            <button className={`icon-btn`} onClick={()=>setForward(!forward)}><Link iconClass={'w-4 h-4'}/></button>
            :
            <button className={`icon-btn`} disabled={true}><Link iconClass={'w-4 h-4'}/></button>}

            <button className={`icon-btn ${sideTaskId===task.id? 'active': ''}`} onClick={()=>{toggleSideBar()}}><SideBarIcon iconClass={'w-4 h-4'}/></button>
             
            </div>
            </div>
              
            </div>
            
        </div>
       
        <Transition
        show={edit}
        enter="transition-all transform  duration-150"
        enterFrom="opacity-0 -translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all transform duration-150"
        leaveFrom="opacity-50 translate-y-0"
        leaveTo="opacity-0 -translate-y-6"
        >
          <EditTask
          task={task}
          index={index}
          chIndex={chIndex}
          setTask={setTask}
          priorityOptions={priorityOptions}
          category={category}
          setEdit={setEdit}
          />
        </Transition>
       
        {!fromDoneTask?
        <Transition
        show={forward}
        enter="transition-all transform  duration-150"
        enterFrom="opacity-0 -translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all transform duration-150"
        leaveFrom="opacity-50 translate-y-0"
        leaveTo="opacity-0 -translate-y-6"
        >
          <ForwardTask
          task={task}
          setForward={setForward}
          jobStatuses={jobStatuses}
          paymentOptions={paymentOptions}
          paymentMethods={paymentMethods}
          jobTypes={jobTypes}
          shipments={shipments}
          productions={productions}
          checklists={checklists}
          quotes={quotes}
          employeelist={employeelist}
          parentIndex={index}
          />
        </Transition>
        :
        null
        }
        {isDelete?
          <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
          <div className='flex flex-row items-center justify-center min-h-screen'>
              <div className='w-11/12 md:w-1/2 lg:w-1/3 card'>
                  <p className='text-slate-600 dark:text-slate-400'>Are you sure you want to delete Job <strong> #{task.id}</strong> ?</p>
                  <p className='text-sm text-rose-500'>This cannot be undone.</p>

                  <div className='flex items-center justify-end gap-2'>
                      <button className='reject-btn w-14' onClick={()=>{deleteJob(); setIsDelete(false);}}>Yes</button>
                      <button className='accept-btn w-14' onClick={()=>setIsDelete(false)}>No</button>
                  </div>
              </div>
          </div>
          </div>
          :
          null
        }
    </div>
  )
}

export default Task