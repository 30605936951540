import React, { useState } from 'react'
import PostTaskCustomer from './PostTaskCustomer';
import Task from './Task';

import { ThemeContext } from './../layout/ThemeContext';
import PostAddChildTask from './PostAddChildTask';
const SearchDataView = ({tasks,custTasks,
                        activeJob,
                        jobStatuses,
                        paymentOptions,
                        paymentMethods,
                        jobTypes,
                        shipments,
                        productions,
                        quotes,
                        checklists,
                        employeelist,
                        catId,
                        compId,
                        isCategory,
                        }) => {


const priorityOptions = [
    { value: "1", label: "low", color: "#22c55e" },
    { value: "2", label: "medium", color: "#3b82f6" },
    { value: "3", label: "high", color: "#f97316" },
    { value: "4", label: "urgent", color: "#ef4444" },
    { value: "5", label: "instant", color: "#ef4444" },
    ];

    // const [sideTaskId, setSideTaskId]=useState(-1);
   
  const { sideTaskId, setSideTaskId } = React.useContext(ThemeContext);
  const [sideTask, setSideTask]=useState([]);
  const [activeTask, setActiveTask]=useState(activeJob? activeJob : -1)
  return (


    
    <div className='pt-12'>
        <div className='w-full'>
        {tasks.length>0?
        <>
        <h4 className='px-2 mb-1 text-xl font-bold text-slate-700 dark:text-slate-400'>Job Results: {tasks.length}</h4>
        <div className='w-full max-h-[45vh] overflow-y-auto overflow-x-hidden md:w-3/5 xl:w-3/4 2xl:w-[76.5vw]'>
        {tasks.map((t,index)=>(
        <div key={`searchwrap-${index}`}>
          {/* Customer */}

          {t.length>0?
            <>
            {t[0].customer!=null?
            <PostTaskCustomer sl={index+1} key={`cust${t[0].customerId}`} referenceId={t[0].id} customer={t[0].customer}/> 
            
            :
            <div className='flex items-center justify-start gap-1 bg-slate-50 dark:bg-slate-900 rounded-t-md'>
                      <p className='px-1 ml-1 text-xs font-semibold rounded-md text-slate-600 bg-slate-300 dark:bg-slate-600 dark:text-slate-200'>Sl.{index+1}</p>
                      <p className='text-sm text-slate-500 rounded-t-md'>unlisted</p>
            </div>
            }
            </>
            :
            null
            }
              <div className='relative'>

             
              <>
                <div className={`absolute left-0  h-[calc(100%-15px)] w-[2px] bg-slate-400/50 dark:bg-slate-600 hidden xl:block`}>
                </div>
                <div>
                <>
                      {t.map((n,nIndex)=>(
                      <div key={`saerch-nested-wrapper_${n.id}${nIndex}`}  className='flex items-center justify-start'>
                              <div className={`w-[8px] h-[2px] bg-slate-400/50 dark:bg-slate-600' hidden xl:block`}></div>
                              <div className='w-full'>
                                <Task key={`${n.id}${nIndex}`}
                                job={n}
                                index={index}
                                chIndex={nIndex}
                                referenceId={n.referenceId}
                                jobStatuses={jobStatuses}
                                priorityOptions={priorityOptions}
                                paymentOptions={paymentOptions}
                                paymentMethods={paymentMethods}
                                jobTypes={jobTypes}
                                shipments={shipments}
                                productions={productions}
                                quotes={quotes}
                                checklists={checklists}
                                employeelist={employeelist}
                                sideTaskId={sideTaskId}
                                setSideTaskId={setSideTaskId}
                                setSideTask={setSideTask} 
                                activeTask={activeTask}
                                dropRev={index>=tasks.length-2}
                                fromSearch={true}
                                />
                            </div>
                            </div>
                      )

                  )}

                   

                    {t[0].customer!=null?
                        
                          <PostAddChildTask
                          catId={catId}
                          compId={compId}
                          referenceId={t[0].referenceId}
                          custId={t[0].customer.id}
                          parentIndex={index}
                          prevLength={t.length}
                          isCategory={isCategory}
                          jobStatuses={jobStatuses}
                          priorityOptions={priorityOptions}
                          paymentOptions={paymentOptions}
                          paymentMethods={paymentMethods}
                          jobTypes={jobTypes}
                          shipments={shipments}
                          productions={productions}
                          quotes={quotes}
                          checklists={checklists}
                          employeelist={employeelist}
                          />

                        :
                        null}
       
                  </>
                </div>
              </>
            
              </div>

        </div>
        )
        )}
        </div>
        </>
        :
        null
        }
        <h4 className='px-2 pt-2 mb-1 text-xl font-bold text-slate-700 dark:text-slate-400'>Customer Job Results: {custTasks.length}</h4>
        {custTasks.length>0?
        <>
        <div className='w-full max-h-[45vh] overflow-y-scroll overflow-x-hidden md:w-3/5 xl:w-3/4 2xl:w-[76.5vw]'>
        {custTasks.map((t,index)=>(
        <div key={`searchwrap-${index}`}>
          {/* Customer */}

          {t.length>0?
            <>
            {t[0].customer!=null?
            <PostTaskCustomer sl={index+1} key={`cust${t[0].customerId}`} referenceId={t[0].id} customer={t[0].customer}/> 
            
            :
            <div className='flex items-center justify-start gap-1 bg-slate-50 dark:bg-slate-900 rounded-t-md'>
                      <p className='px-1 ml-1 text-xs font-semibold rounded-md text-slate-600 bg-slate-300 dark:bg-slate-600 dark:text-slate-200'>Sl.{index+1}</p>
                      <p className='text-sm text-slate-500 rounded-t-md'>unlisted</p>
            </div>
            }
            </>
            :
            null
            }
              <div className='relative'>

             
              <>
                <div className={`absolute left-0  h-[calc(100%-15px)] w-[2px] bg-slate-400/50 dark:bg-slate-600 hidden xl:block`}>
                </div>
                <div>
                <>
                      {t.map((n,nIndex)=>(
                      <div key={`saerch-nested-wrapper_${n.id}${nIndex}`}  className='flex items-center justify-start'>
                              <div className={`w-[8px] h-[2px] bg-slate-400/50 dark:bg-slate-600' hidden xl:block`}></div>
                              <div className='w-full'>
                                <Task key={`${n.id}${nIndex}`}
                                job={n}
                                index={index}
                                chIndex={nIndex}
                                referenceId={n.referenceId}
                                jobStatuses={jobStatuses}
                                priorityOptions={priorityOptions}
                                paymentOptions={paymentOptions}
                                paymentMethods={paymentMethods}
                                jobTypes={jobTypes}
                                shipments={shipments}
                                productions={productions}
                                quotes={quotes}
                                checklists={checklists}
                                employeelist={employeelist}
                                sideTaskId={sideTaskId}
                                setSideTaskId={setSideTaskId}
                                setSideTask={setSideTask} 
                                activeTask={activeTask}
                                dropRev={index>=tasks.length-2}
                                fromSearch={true}
                                />
                            </div>
                            </div>
                      )

                  )}

                   

                     {t[0].customer!=null ?
                        
                          <PostAddChildTask
                          catId={catId}
                          compId={compId}
                          referenceId={t[0].referenceId}
                          custId={t[0].customer.id}
                          parentIndex={index}
                          prevLength={t.length}
                          isCategory={isCategory}
                          jobStatuses={jobStatuses}
                          priorityOptions={priorityOptions}
                          paymentOptions={paymentOptions}
                          paymentMethods={paymentMethods}
                          jobTypes={jobTypes}
                          shipments={shipments}
                          productions={productions}
                          quotes={quotes}
                          checklists={checklists}
                          employeelist={employeelist}
                          />

                        :
                        null} 
       
                  </>
                </div>
              </>
            
              </div>

        </div>
        )
        )}
        </div>
        </>
        :
        null
        }
      </div>
            
            {/* <div className={`searchSidebarRight2 ${sideTaskId!=-1? 'right-12' : '-right-full'}`}>
                  <TaskSideBar
                  sideTaskId={sideTaskId}
                  sideTask={sideTask}
                  setSideTaskId={setSideTaskId}
                  employeelist={employeelist}
                  checklists={checklists}
                  />
            </div> */}


    </div>
  )
}

export default SearchDataView