import React, { useEffect, useState } from 'react'

import Avatar from '../layout/Avatar'

import { Pencil, Add } from '../layout/Icons';

import {useNavigate} from "react-router-dom";
import axios from "../../api/axios";
import { SEARCHTASK } from '../../api/ApiUrl';
import useDebounce from './DebounceVal';


const TaskHeader = ({name,icon, role, addTask,setAddTask, catId, compId, setSearchJobs,setSearchCustJobs }) => {


    const [search, setSearch]=useState('')
    const navigate = useNavigate();  

    const dbSearch=useDebounce(search, 500)

    const getSearchData=async(query)=>{
        console.log(query);
        await axios.get(`${SEARCHTASK}`,{  params:{ query: `${query}`, catId:catId, compId:compId }}).then((res)=>{
            setSearchJobs(res.data.tasks)
            setSearchCustJobs(res.data.customerTasks)
        })
    }
    

    useEffect(()=>{
        if(dbSearch.length>1){
            getSearchData(dbSearch)
        }else{
            setSearchJobs([])
            setSearchCustJobs([])
        }
    },[dbSearch])


    const categoryEdit=(id)=>{
        if(id>0){
            navigate(`/category/edit/${id}`);
        }
    }

    return (
        <div>
        <div className='flex items-center justify-between gap-2 p-1 mb-1 bg-slate-50 dark:bg-slate-800 rounded-b-md'>
            <div className='flex items-center justify-start gap-2 group'>
                {icon!=null?
                <img src={`${icon}`} className='w-10 h-10 rounded-full'/>
                :
                <Avatar value={name} width='10' height='10' color={'bg-orange-300 dark:bg-orange-800/70'}/>   
                }

                <h4 className='text-lg font-bold text-slate-600 dark:text-slate-400'>{name}</h4>


                {role==2?
                <button className='visible icon-btn lg:invisible group-hover:visible' onClick={()=>categoryEdit(catId)} ><Pencil iconClass={'w-4 h-4'}/></button>
                :
                null
                }
            </div>

            <input 
                type={'text'}
                placeholder={`search`}
                className={'search-field w-[30vw]'}
                onChange={(e)=>{setSearch(e.target.value)}}
            />

                {role>=1 &&
                    <button className={`plus-add-task ${addTask? `rotate-45`:`rotate-0`}`} onClick={()=>{setAddTask(!addTask)}}><Add iconClass={'w-5 h-5'}/></button>
                }
        </div>
        

        </div>
    )
    
    }

export default TaskHeader