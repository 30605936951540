import React,{useState, useEffect,lazy, Suspense} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { CategorylogoStorage_LINK, CUSTOMER_URL, GETCATEGORYJOBS_URL, GETDATA_URL } from '../../api/ApiUrl';
import CategoryCard from '../Tasks/CategoryCard';
import CategoryCardLoader from '../loaders/CategoryCardLoader';
import { Transition } from '@headlessui/react'

import CreateTaskForm from '../Tasks/CreateTaskForm';
import Tasklist from '../Tasks/Tasklist';
import { setTaskPagination } from '../../features/taskPaginations';
import { setTasks } from '../../features/Tasks';
import { setPinnedTasks } from '../../features/PinnedTasks';
import TaskHeader from '../Tasks/TaskHeader';
import SearchDataView from '../Tasks/SearchDataView';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, requireRefresh } from '../../config/firebaseConfig';

const CategoryTasks = () => {

  let {catId} = useParams();
  let {compId}=useParams();
  let {activeJob}=useParams();
  
  const dispatch=useDispatch();

  const categs=useSelector((state)=>state.category.value);
  const user=useSelector((state)=>state.user.value);
    // const tasks=useSelector((state)=>state.allTasks.value); 

    const [category, setCategory]=useState(null);
    const [jobStatuses,setJobStatuses]=useState([]);
    const [paymentOptions,setPaymentOptions]=useState([]);
    const [paymentMethods,setPaymentMethods]=useState([]);
    const [jobTypes,setJobTypes]=useState([]);
    const [shipments,setShipments]=useState([]);
    const [productions,setProductions]=useState([]);
    const [quotes, setQuotes]=useState([]);
    const [checklists, setChecklists]=useState([]);
    const [employeelist,setEmployeelist]=useState([]);

    
    const [isLoad, setLoad]=useState(true);
    const [addTask, setAddTask]=useState(false);
 


    const [customerData, setCustomerData]=useState([]);

    const getCustomers = async(companyId) => {
      const res= await axios.get(`${CUSTOMER_URL}${companyId}`)
      setCustomerData(res.data.customers);
    }

    const getDefaultData= async(ids)=>{
        try{
           await axios.post(GETDATA_URL, {ids:ids}).then((res)=>{
            
            setJobStatuses(res.data.statuses)
            setPaymentOptions(res.data.paymentOptions)
            setPaymentMethods(res.data.paymentMethods)
            setJobTypes(res.data.jobTypes)
            setShipments(res.data.shipments)
            setProductions(res.data.productions)
            setQuotes(res.data.quotes)
            setChecklists(res.data.checklists)
            setEmployeelist(res.data.employees)   
            setLoad(false)
           })
      
          }catch(e){
            if(!e?.response){
              toast.error("No Server Response!");
              console.log(e);
          }else{
            if(e.response.status===429){
              toast.error('too many requests! please try again later')
            }else{
              console.log(e);
              toast.error("Oops! An error occured!");
            }
          }
        }
    }

    const getCategoryTask=async(catId)=>{
      try{

        await axios.get(`${GETCATEGORYJOBS_URL}${catId}`).then((res)=>{
          dispatch(setTaskPagination(res.data.tasks.next_page_url))
       
          dispatch(setTasks(res.data.tasks.data))
          dispatch(setPinnedTasks(res.data.pinned))
        })


      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }else{
          if(e.response.status===429){
            toast.error('too many requests! please try again later')
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
    }

       
    useEffect(()=>{
      const uid = user.uid;
      const listenUserState = onSnapshot(doc(db, "UserState", `${user.id}`), (doc) => {
        const uState=doc.data();
        if(uState.status==1 && uState.idle==true && uState.uid!==uid){
          if(uState.updateTask==true){
            getCategoryTask(catId)
            requireRefresh(user.id, false, uState.updateCategory ,uState.updateComp)
            
          }
        }
    });

    return ()=>{
      listenUserState()
    }
    },[])

    useEffect(()=>{
      
    const getCategory=(id)=>{
        if(categs!=null){
            setCategory(categs.filter((cat)=>cat.id===parseInt(id))[0])
            getCustomers(compId);
            getDefaultData([parseInt(id)]);
        }
    }
    getCategory(catId);
    getCategoryTask(catId)

    return ()=>{
      setCategory(null);
      setJobStatuses([]);
      setPaymentOptions([]);
      setPaymentMethods([]);
      setJobTypes([]);
      setShipments([]);
      setProductions([]);
      setQuotes([]);
      setChecklists([]);
      setEmployeelist([]);
      setLoad(true);
    }
},[catId, categs])

const [searchJobs, setSearchJobs]=useState([])
const [searchCustJobs, setSearchCustJobs]=useState([])

  return !isLoad?(

    <div className='relative main-bodyLayout'>
        <div className={`p-1 rounded-t-md bg-slate-50 dark:bg-slate-800 ${addTask? `mb-9`: `mb-0`} `}>
            {category!=null?
            <div className='relative z-[1024]'>
            <TaskHeader
            name={category.name} 
            icon={`${CategorylogoStorage_LINK}${category.icon}`}
            addTask={addTask} 
            setAddTask={setAddTask}
            role={category.roleId}
            catId={category.id}
            compId={-1}
            setSearchJobs={setSearchJobs}
            setSearchCustJobs={setSearchCustJobs}
            />
            </div>
            :
            <CategoryCardLoader/>
            }
        
          {category!=null?
             <div className={`${addTask? `block h-full translate-y-0`: `hidden h-0 -translate-y-6`} transition-all duration-150 ease-in-out  transform relative z-40`}
             >
                <CreateTaskForm
                key={category.id}
                isCategory={true}
                compId={category.companyId}
                customerData={customerData}
                category={category}
                catId={catId}
                jobStatuses={jobStatuses}
                paymentOptions={paymentOptions}
                paymentMethods={paymentMethods}
                jobTypes={jobTypes}
                shipments={shipments}
                productions={productions}
                quotes={quotes}
                checklists={checklists}
                employeelist={employeelist}
                />
        
            </div>
            :
            null
            }

          </div>
          
          <div>
            
            {searchJobs.length>0 || searchCustJobs.length>0?
          <div className='fixed top-0 z-[40] w-[100vw] h-full py-1 overflow-x-hidden rounded-md shadow-xl bg-slate-400/50 dark:bg-black/50 backdrop-blur-md'>
              <SearchDataView
              tasks={searchJobs}
              custTasks={searchCustJobs}
              activeJob={activeJob}
              compId={compId}
              catId={catId}
              isCategory={true}
              jobStatuses={jobStatuses}
              paymentOptions={paymentOptions}
              paymentMethods={paymentMethods}
              jobTypes={jobTypes}
              shipments={shipments}
              productions={productions}
              quotes={quotes}
              checklists={checklists}
              employeelist={employeelist}
              />
            </div>
            :
            null
            }
            </div> 

          {category!=null && 
            <Tasklist
            activeJob={activeJob}
            addTask={addTask}
            key={category.id}
            isCategory={true}
            // tasks={tasks.filter(t=>t.companyId==compId)}
            compId={compId}
            catId={catId}
            jobStatuses={jobStatuses}
            paymentOptions={paymentOptions}
            paymentMethods={paymentMethods}
            jobTypes={jobTypes}
            shipments={shipments}
            productions={productions}
            quotes={quotes}
            checklists={checklists}
            employeelist={employeelist}
            fromDoneTask={false}
            title={null}
            />
         
        }

          
    </div>
  )
  :
  null
}

export default CategoryTasks