import { createSlice, current  } from "@reduxjs/toolkit";

const initialStateValue=[];


export const pinnedTaskSlice=createSlice({
    name:'pinnedTasks',
    initialState:{value:initialStateValue},
    reducers:{
        setPinnedTasks:(state, action)=>{
            state.value=action.payload
        },
        addPinnedTask:(state,action)=>{
            state.value=[action.payload,...state.value]
        },

        updatePinnedTaskStatus:(state,action)=>{

            var updateJob=action.payload.task;
            var parentIndex=action.payload.index;
            var childIndex=action.payload.chIndex;

            var isSearch=action.payload.fromSearch;

            var allTasks=state.value
            
            var oldTask=allTasks[parentIndex];

            if(!isSearch){
                oldTask=oldTask.filter((j,i)=>i!=childIndex)
                oldTask=[updateJob, ...oldTask]

                allTasks=allTasks.filter((t,i)=>i!=parentIndex)

                allTasks=[oldTask,...allTasks]

                
            }else{
                
                var ref=updateJob.referenceId;
                var parentPos=-1;

                allTasks.forEach((t,index)=>{
                    t.forEach((j,childIndex)=>{
                        if(j.referenceId==ref){
                            parentPos=index
                        }
                    })
                })

                if(parentPos!=-1){
                    var nestJobs=allTasks.filter((t,i)=>i==parentPos)[0]
                    nestJobs=nestJobs.filter((j,i)=>i!=childIndex)
                    nestJobs=[updateJob, ...nestJobs]
                   
                    allTasks[parentPos]=nestJobs

                }else{
                    allTasks=[[updateJob],...allTasks]
                }
                
            }
            state.value=allTasks
        },

        addToPinTask:(state,action)=>{
            
            var job=action.payload.task
            var ref=action.payload.ref
            var allTasks=state.value
            
          

            var parentPos=-1

            allTasks.forEach((t,index)=>{
                t.forEach((j,chIndex)=>{
                    if(j.referenceId==ref){
                        parentPos=index
                    }
                })
            })
    
            if(parentPos!=-1){
                var nestJobs=allTasks.filter((t,i)=>i==parentPos)[0]
                nestJobs=[job, ...nestJobs]
               
                allTasks[parentPos]=nestJobs
                
            }
            else{
                allTasks=[[job],...allTasks]
            }
            
            state.value=allTasks
           
        },
        removeFromPinTask:(state,action)=>{

            var parentIndex=action.payload.index;
            var childIndex=action.payload.chIndex;
            var allTasks=state.value

            allTasks[parentIndex]=allTasks[parentIndex].filter((j,i)=>i!=childIndex)
            
            state.value=allTasks
        }

    }
})

export const {setPinnedTasks, addPinnedTask, updatePinnedTaskStatus, addToPinTask, removeFromPinTask}=pinnedTaskSlice.actions
export default pinnedTaskSlice.reducer;