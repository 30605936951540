import { createSlice , current  } from "@reduxjs/toolkit";

const initialStateValue=[];

export const taskSlice=createSlice({
    name:'allTasks',
    initialState:{value:initialStateValue},
    reducers:{
        setTasks:(state, action)=>{
            state.value=action.payload
        },
        addTask:(state,action)=>{
            state.value=[action.payload,...state.value]
        },
        addPageTask:(state,action)=>{
            state.value=[...state.value, ...action.payload]
        },
        updateTaskCustomer:(state, action)=>{
            state.value=state.value.map((t)=>t.referenceId===action.payload.reference?
            {
            ...t,
            customer:action.payload.customer
            }:
            t)
        },
        updateTaskStatus:(state,action)=>{

            var updateJob=action.payload.task;
            var parentIndex=action.payload.index;
            var childIndex=action.payload.chIndex;
            var isSearch=action.payload.fromSearch;

            var allTasks=state.value
            
            if(!isSearch){

                var oldTask=allTasks[parentIndex];
                
                oldTask=oldTask.filter((j,i)=>i!=childIndex)
                oldTask=[updateJob, ...oldTask]
                
                allTasks=allTasks.filter((t,i)=>i!=parentIndex)
                
                allTasks=[oldTask,...allTasks]
           
            }else{
                var ref=updateJob.referenceId;
                var parentPos=-1;

                allTasks.forEach((t,index)=>{
                    t.forEach((j,childIndex)=>{
                        if(j.referenceId==ref){
                            parentPos=index
                        }
                    })
                })

                if(parentPos!=-1){
                    var nestJobs=allTasks.filter((t,i)=>i==parentPos)[0]
                    nestJobs=nestJobs.filter((j,i)=>i!=childIndex)
                    nestJobs=[updateJob, ...nestJobs]
                   
                    allTasks[parentPos]=nestJobs

                }else{
                    allTasks=[[updateJob],...allTasks]
                }
            }

            state.value=allTasks
          
        },

        deleteTask:(state, action)=>{

            var index=action.payload.index
            var chIndex=action.payload.chIndex

            var allTasks=state.value

            var oldTasks=allTasks[index]
            
            oldTasks=oldTasks.filter((j,i)=>i!=chIndex)
            allTasks[index]=oldTasks

            state.value=allTasks


        },
        pushToPinTask:(state, action)=>{

           
            var parentIndex=action.payload.index;
            var childIndex=action.payload.chIndex;
            var allTasks=state.value

            var oldTask=allTasks[parentIndex];
        
            oldTask=oldTask.filter((j,i)=>i!=childIndex)
            allTasks[parentIndex]=oldTask

            state.value=allTasks
        },

        postAddTask:(state,action)=>{
            
            var allTasks=state.value
            var jobs=action.payload.tasks

            var parentIndex=action.payload.pIndex;

            var nestJobs=allTasks[parentIndex]
            
            nestJobs=[...jobs, ...nestJobs]

            allTasks=allTasks.filter((j,i)=>i!=parentIndex)

            allTasks=[nestJobs, ...allTasks]

            state.value=allTasks
        }

    }
})

export const {setTasks, updateTaskCustomer, updateTaskStatus, deleteTask, addTask, pushToPinTask, addPageTask, postAddTask}=taskSlice.actions
export default taskSlice.reducer;