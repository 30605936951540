import React,{useState, useEffect}  from 'react'
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { CompanylogoStorage_LINK, CUSTOMER_URL, GETCOMPANYJOBS_URL, GETDATA_URL } from '../../api/ApiUrl';
import CategoryCardLoader from '../loaders/CategoryCardLoader';
import CompanyCard from '../Tasks/CompanyCard';
import { Transition } from '@headlessui/react'
import CreateTaskForm from '../Tasks/CreateTaskForm';
import Tasklist from '../Tasks/Tasklist';
import { setCustomer } from '../../features/customers';
import { setTasks } from '../../features/Tasks';
import { setTaskPagination } from '../../features/taskPaginations';
import { setPinnedTasks } from '../../features/PinnedTasks';
import TaskHeader from '../Tasks/TaskHeader';
import SearchDataView from '../Tasks/SearchDataView';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, requireRefresh } from '../../config/firebaseConfig';


const CompanyTasks = () => {
  
    const dispatch = useDispatch();
    let {compId} = useParams();
    let {activeJob}=useParams();
    const user=useSelector((state)=>state.user.value);
    const categs=useSelector((state)=>state.category.value);
    const companies=useSelector((state)=>state.company.value);
    // const [tasks, setTasks]=useState([]);
    // const [total, setTotal]=useState(0);
    //  const tasks=useSelector((state)=>state.allTasks.value); 
   
   
    const [company, setCompany]=useState(null);
    const [jobStatuses,setJobStatuses]=useState([]);
    const [paymentOptions,setPaymentOptions]=useState([]);
    const [paymentMethods,setPaymentMethods]=useState([]);
    const [jobTypes,setJobTypes]=useState([]);
    const [shipments,setShipments]=useState([]);
    const [productions,setProductions]=useState([]);
    const [quotes, setQuotes]=useState([]);
    const [checklists, setChecklists]=useState([]);
    const [employeelist,setEmployeelist]=useState([]);

    const [isLoad, setLoad]=useState(true);
    const [addTask, setAddTask]=useState(false);


    // const getJobs = async(id)=>{

    //     try{
    //      await axios.get(`${GETCOMPANYJOBS_URL}${id}`).then((res)=>{
          
    //       setTasks(res.data.tasks);
    //      })
    //      setTaskLoad(false);
    //     }catch(e){
    //       if(!e?.response){
    //               toast.error("No Server Response!");
    //               console.log(e);
    //           }else{
    //               if(e.response.status===429){
    //                 toast.error('too many requests! please try again later')
    //               }else{
    //                 console.log(e);
    //                 toast.error("Oops! An error occured!");
    //               }
    //       }
    //     }
    // }
    
    const [customerData, setCustomerData]=useState([]);

    const getCustomers = async(companyId) => {
      const res= await axios.get(`${CUSTOMER_URL}${companyId}`)
      setCustomerData(res.data.customers);
    }

    const getDefaultData= async(ids)=>{
        try{
           await axios.post(GETDATA_URL, {ids:ids}).then((res)=>{
            setJobStatuses(res.data.statuses)
            setPaymentOptions(res.data.paymentOptions)
            setPaymentMethods(res.data.paymentMethods)
            setJobTypes(res.data.jobTypes)
            setShipments(res.data.shipments)
            setProductions(res.data.productions)
            setQuotes(res.data.quotes)
            setChecklists(res.data.checklists)
            setEmployeelist(res.data.employees)    
           })
      
          }catch(e){
            if(!e?.response){
              toast.error("No Server Response!");
              console.log(e);
          }else{
            if(e.response.status===429){
              toast.error('too many requests! please try again later')
            }else{
              console.log(e);
              toast.error("Oops! An error occured!");
            }
          }
        }
    }

    const getCompanyTask = async(companyId)=>{
      try{
        await axios.get(`${GETCOMPANYJOBS_URL}${companyId}`).then((res)=>{
          dispatch(setTaskPagination(res.data.tasks.next_page_url))
       
          dispatch(setTasks(res.data.tasks.data))
          dispatch(setPinnedTasks(res.data.pinned))
         
        })
        
      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
      }else{
        if(e.response.status===429){
          toast.error('too many requests! please try again later')
        }else{
          console.log(e);
          toast.error("Oops! An error occured!");
        }
      }
    }
      

    }

    
    useEffect(()=>{
      const uid = user.uid;
      const listenUserState = onSnapshot(doc(db, "UserState", `${user.id}`), (doc) => {
        const uState=doc.data();
        if(uState.status==1 && uState.idle==true && uState.uid!==uid){
          if(uState.updateTask==true){
            getCompanyTask(compId)
            requireRefresh(user.id, false, uState.updateCategory ,uState.updateComp)
            
          }
        }
    });

    return ()=>{
      listenUserState()
    }
    },[])


    useEffect(()=>{
    var comp=companies.filter((co)=>co.id===parseInt(compId))[0];
    setCompany(comp);
    if(categs.length>0){
        var ids=categs.filter(c=>c.companyId===parseInt(compId)).map((c)=>c.id);
        getCustomers(compId);
        getDefaultData(ids);
        getCompanyTask(compId);
        // getJobs(compId);
    }

    setLoad(false);

    return ()=>{
        setCompany(null);
        setJobStatuses([]);
        setPaymentOptions([]);
        setPaymentMethods([]);
        setJobTypes([]);
        setShipments([]);
        setProductions([]);
        setQuotes([]);
        setChecklists([]);
        setEmployeelist([]);
        setTasks([])
        setLoad(true)
        // setTaskLoad(true)
    }

    },[compId, categs])


    const [searchJobs, setSearchJobs]=useState([])
    const [searchCustJobs, setSearchCustJobs]=useState([])


  return !isLoad?(
    <div className='relative main-bodyLayout'>
        <div className={`${addTask? `mb-9`: `mb-0`}`}>
        {company!=null?
        <div className='relative z-[1024]'>
        <TaskHeader 
        name={company.name} 
        icon={`${CompanylogoStorage_LINK}${company.logo}`}
        addTask={addTask} 
        setAddTask={setAddTask}
        role={1}
        catId={-1}
        compId={company.id}
        setSearchJobs={setSearchJobs}
        setSearchCustJobs={setSearchCustJobs}
        />
        {/* <CompanyCard company={company} addTask={addTask} setAddTask={setAddTask} /> */}
      
        </div>
        :
        <CategoryCardLoader/>
        }

        {company!=null?

        <div className={`${addTask? `block h-full translate-y-0`: `hidden h-0 -translate-y-6`} transition-all duration-150 ease-in-out  transform relative z-50`}
            >
              
                <CreateTaskForm
                isCategory={false}
                compId={compId}
                customerData={customerData}
                jobStatuses={jobStatuses}
                paymentOptions={paymentOptions}
                paymentMethods={paymentMethods}
                jobTypes={jobTypes}
                shipments={shipments}
                productions={productions}
                quotes={quotes}
                checklists={checklists}
                employeelist={employeelist}
                setAddTask={setAddTask}
                />

            </div>

            :
            null
        } 

        <div>
        {searchJobs.length>0 || searchCustJobs.length>0?
        <div className='fixed top-0 z-[40] w-[100vw] h-full py-1 overflow-x-hidden rounded-md shadow-xl bg-slate-400/50 dark:bg-black/50 backdrop-blur-md'>
          <SearchDataView
          tasks={searchJobs}
          compId={compId}
          catId={null}
          isCategory={false}
          custTasks={searchCustJobs}
          activeJob={activeJob}
          jobStatuses={jobStatuses}
          paymentOptions={paymentOptions}
          paymentMethods={paymentMethods}
          jobTypes={jobTypes}
          shipments={shipments}
          productions={productions}
          quotes={quotes}
          checklists={checklists}
          employeelist={employeelist}
          />
        </div>
        :
        null
        }
        </div>   

        </div>
        
            <Tasklist
            activeJob={activeJob}
            addTask={addTask}
            key={compId}
            isCategory={false}
            // tasks={tasks.data}
            compId={compId}
            jobStatuses={jobStatuses}
            paymentOptions={paymentOptions}
            paymentMethods={paymentMethods}
            jobTypes={jobTypes}
            shipments={shipments}
            productions={productions}
            quotes={quotes}
            checklists={checklists}
            employeelist={employeelist}
            fromDoneTask={false}
            title={null}
            />
        
            
    </div>
  )
  :
  null
}

export default CompanyTasks