import { createSlice } from "@reduxjs/toolkit";

const initialStateValue=null;

export const taskPaginationSlice=createSlice({
    name:"taskPagination",
    initialState:{value:initialStateValue},
    reducers:{
        setTaskPagination:(state,action)=>{
            state.value=action.payload
        },
    }
})

export const {setTaskPagination}=taskPaginationSlice.actions

export default taskPaginationSlice.reducer;